export type AuthorizationHeader = { Authorization?: string }

enum AuthorizationType {
  Basic = 'Basic',
  Bearer = 'Bearer',
}

type Token = string | null
type TokenGetter = () => Token
type TokenSetter = (token: Token) => void

export class Authorization {
  constructor(private token: Token, readonly use: boolean, readonly authType: AuthorizationType | null, private tokenGetter: TokenGetter, private tokenSetter: TokenSetter) {}

  getAuthHeaders(): AuthorizationHeader {
    const token = this.tokenGetter();
    const authHeaders: AuthorizationHeader = {};
    if (this.use && token !== null) {
      authHeaders.Authorization = `${this.authType} ${token}`;
    }
    return authHeaders;
  }

  setTokenGetter(tokenGetter: TokenGetter): void {
    this.tokenGetter = tokenGetter;
  }

  setTokenSetter(tokenSetter: TokenSetter): void {
    this.tokenSetter = tokenSetter;
  }

  setToken(token: Token): void {
    this.token = token;
    this.tokenSetter(token);
  }
}

export const CookieAuth = (): Authorization => new Authorization(null, false, null, () => null, () => {});
export const BasicAuth = (token: Token): Authorization => new Authorization(token, false, AuthorizationType.Basic, () => null, () => {});
export const BearerAuth = (token: Token): Authorization => new Authorization(token, false, AuthorizationType.Bearer, () => null, () => {});

