import { encode as safeEncode } from 'url-safe-base64';
import { flow } from 'fp-ts/es6/function';

const encode = flow(
  encodeURIComponent,
  s => s.replace(
    /%([0-9A-F]{2})/g,
    (match, p1: string) => String.fromCharCode(parseInt('0x' + p1, 16)),
  ),
  btoa,
  safeEncode,
  s => s.replace(/\.{0,2}/g, ''),
);

export { encode };
